import axios from '../utils/axios'

let baseURL = '/sysDict'

// 字典管理列表
export function getQueryDictionaryMasterDataAPI(params) {
    return axios.post(baseURL+'/queryDictionaryMasterData', params)
}
// 字典管理-新增
export function addDictionaryMasterAPI(params) {
    return axios.post(baseURL+'/addDictionaryMaster', params)
}
// 字典管理-修改
export function updateDictionaryMasterByIdAPI(params) {
    return axios.post(baseURL+'/updateDictionaryMasterById', params)
}
// 字典管理-删除
export function deleteDictionaryMasterByIdAPI(params) {
    return axios.post(baseURL+'/deleteDictionaryMasterById', params)
}
// 详情部分
// 详情列表
export function getQueryDictionaryDetailDataAPI(params) {
    return axios.post(baseURL+'/queryDictionaryDetailData', params)
}
// 详情修改
export function updateDictionaryDetailByIdAPI(params) {
    return axios.post(baseURL+'/updateDictionaryDetailById', params)
}
// 详情新增
export function addDictionaryDetailAPI(params) {
    return axios.post(baseURL+'/addDictionaryDetail', params)
}
// 详情删除
export function deleteDictionaryDetailByIdAPI(params) {
    return axios.post(baseURL+'/deleteDictionaryDetailById', params)
}