<template>
    <div class="user-list">
        <!-- 筛选项 -->
        <el-form :inline="true" size="mini" :model="form" ref="filterFormRef" label-width="100px">
            <el-form-item label="字典类型:" prop="dictType">
                <el-input style="width: 200px;" v-model="form.dictType" placeholder="请输入字典类型" clearable></el-input>
            </el-form-item>
            <el-form-item label="字典名称:" prop="dictName">
                <el-input style="width: 200px;" v-model="form.dictName" placeholder="请输入字典类型" clearable></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 20px;">
                <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 筛选项 -->
        <div class="operate">
            <el-button type="primary" size="mini" icon="el-icon-plus" @click="meuVisibleHnadle">新增</el-button>
        </div>
        <!-- 表格内容 -->
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
            <el-table-column prop="dictId" label="序号" width="80"></el-table-column>
            <el-table-column prop="dictType" label="字典类型"></el-table-column>
            <el-table-column prop="dictName" label="字典名称"></el-table-column>
            <el-table-column prop="createBy" label="创建人"></el-table-column>
            <el-table-column prop="createAt" label="创建时间"></el-table-column>
            <el-table-column prop="updateBy" label="更新人"></el-table-column>
            <el-table-column prop="updateAt" label="更新时间"></el-table-column>
            <el-table-column fixed="right" label="操作" width="260">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="editHandle(scope.row)">
                        编辑
                    </el-button>
                    <el-button size="mini" @click="lookHandle(scope.row)">
                        详情
                    </el-button>
                    <el-button size="mini" plain type="danger" @click="deleteHandle(scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 表格内容 -->
        <!-- 分页 -->
        <el-pagination
                small
                @current-change="handleCurrentChange"
                :page-size="limit"
                layout="total, prev, pager, next, jumper"
                :total="total"
                :current-page="currentPage">
        </el-pagination>
         <el-dialog
                :title="`${type==='add'?'新增':'编辑'}字典项`"
                :visible.sync="meuVisible"
                width="30%"
                @close="meuVisibleClose"
        >
            <el-form
                    class="form"
                    size="mini"
                    :model="menuForm"
                    ref="refMenu"
                    :rules="rules"
                    label-width="100px">
                <el-form-item label="字典类型:"   prop="dictType">
                    <el-input v-model="menuForm.dictType" :disabled="type==='edit'"></el-input>
                </el-form-item>
                <el-form-item label="字典名称:"   prop="dictName">
                    <el-input v-model="menuForm.dictName"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button size="mini" @click="dislogCancelHnadle">取 消</el-button>
              <el-button size="mini" type="primary" @click="submit">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { 
    getQueryDictionaryMasterDataAPI,
    addDictionaryMasterAPI,
    updateDictionaryMasterByIdAPI,
    deleteDictionaryMasterByIdAPI
} from "@/api/sysDict.js";
export default {
    props: {},
    data() {
        return {
            type:'',
            menuForm:{
                dictType: "",
                dictName: ""
            },
            meuVisible:false,
            tableData:[],
            loading:false,
            form: {
                dictType: "",
                dictName: ""
            },
            total: 0,
            currentPage: 1,
            limit: 10,
            systemList:[],
            rules:{
                dictType: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                dictName: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ]
            }
        };
    },
  async  created() {
    await this.getList()
    },
    methods: {
        deleteHandle(row){ // 删除
            this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(async() => {
                   await this.deleteDictionaryMasterByIdList(row)
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
        },
        async  deleteDictionaryMasterByIdList(row){ // 删除
            try {
                let res=await deleteDictionaryMasterByIdAPI({dictId:row.dictId})
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.meuVisible=false
                    await this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });  
            }
        },
      async  getList(){
            try {
                this.loading=true
                let params={
                    pageIndex:this.currentPage,
                    pageSize:this.limit,
                    ...this.form
                }
                this.roleTableLoading=true
                this.tableData=[]
                let res =await getQueryDictionaryMasterDataAPI(params)
                this.tableData=res.data.records
                this.total=res.data.total
                this.currentPage=res.data.current
            } catch (error) {
                
            } finally{
                this.loading=false
            }
        },
        submit(){
            this.$refs.refMenu.validate(async (valid) => {
                if(valid){
                    if(this.type==='add'){
                        await this.addDictionaryMasterList()
                    }else if(this.type==='edit'){
                        await this.updateDictionaryMasterByIdList()
                    }
                }else{
                    return false;
                }
            })
        },
      async  addDictionaryMasterList(){ // 新增
            try {
                let res=await addDictionaryMasterAPI(this.menuForm)
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.meuVisible=false
                    await this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });  
            }
        },
        async  updateDictionaryMasterByIdList(){ // 编辑
            try {
                let params={
                    dictName:this.menuForm.dictName,
                    dictId:this.menuForm.dictId,
                }
                let res=await updateDictionaryMasterByIdAPI(params)
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '编辑成功!'
                    });
                    this.meuVisible=false
                    await this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });  
            }
        },
        meuVisibleClose(m){
            this.meuVisible=false
            this.clearForm()
            this.$refs['refMenu'].resetFields();
        },
        clearForm(){
            Object.keys(this.menuForm).forEach(item=>{
                this.menuForm[item]=''
            })
        },
        dislogCancelHnadle(){ // 弹框的取消
            this.meuVisible = false,
            this.clearForm()
            this.$refs['refMenu'].resetFields();
        },
        // 配置菜单
        meuVisibleHnadle(){
            this. menuForm.dictType='' 
            this. menuForm.dictName='' 
            this.type='add'
            this.meuVisible=true
        },
        // 编辑用户
        async editHandle(row) {
            this.type='edit'
            this.meuVisible=true
            this.menuForm={
                ...row
            }
        },
        lookHandle(row){ 
            this.$router.push({ 
                path: `/dictionary/detail/${row.id}` ,
                query: {
                    ...row
                }
             });//detail
        },
         // 搜索重置
      async   resetFilterForm() {
            this.$refs.filterFormRef.resetFields();
            await this.getList()
        },
     async   handleCurrentChange(val) {
            this.currentPage = val
            await this.getList()
        },
      async  searchList(){
            this.currentPage=1
            await this.getList()
        }
    },
};
</script>

<style scoped lang="scss">
.user-list {
    //overflow-y: scroll;
    .form {
        display: block;
    }

    .operate {
        margin: 25px 0;
        float: right;
    }

    .email_verify_btn {
        background: $color-theme-normal !important;
        color: #ffffff !important;
        border: 2px solid $color-theme-normal !important;
        border-radius: 0 !important;
        font-size: $font-small !important;
    }
    .dialog-footer{
        display: flex;
        justify-content: center;
    }
}
</style>